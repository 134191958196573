import React, { useState } from 'react';
import { Button, message, Spin, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getLeads, bulkAssignLeads } from '../apiService';
import * as XLSX from 'xlsx';
import BulkDeleteLeads from './BulkDeleteLeads';

const { Option } = Select;

const LeadExportAndBulkActions = ({ filters, selectedLeads, users, onBulkAssign, onBulkDelete, isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [bulkAssignUser, setBulkAssignUser] = useState('');

  const fetchAllLeads = async () => {
    let allLeads = [];
    let currentPage = 1;
    let hasMorePages = true;

    while (hasMorePages) {
      try {
        const response = await getLeads({ ...filters, page: currentPage, per_page: 100 });
        allLeads = [...allLeads, ...response.leads];
        
        if (response.leads.length < 100) {
          hasMorePages = false;
        } else {
          currentPage++;
        }
      } catch (error) {
        console.error('Error fetching leads:', error);
        message.error('Failed to fetch all leads');
        return null;
      }
    }

    return allLeads;
  };

  const exportToCSV = async () => {
    setLoading(true);
    const leads = await fetchAllLeads();
    if (leads) {
      const csv = convertToCSV(leads);
      downloadFile(csv, 'leads.csv', 'text/csv;charset=utf-8;');
    }
    setLoading(false);
  };

  const exportToXLSX = async () => {
    setLoading(true);
    const leads = await fetchAllLeads();
    if (leads) {
      const worksheet = XLSX.utils.json_to_sheet(leads);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
      XLSX.writeFile(workbook, "leads.xlsx");
    }
    setLoading(false);
  };

  const convertToCSV = (leads) => {
    const header = Object.keys(leads[0]).join(',') + '\n';
    const rows = leads.map(lead => 
      Object.values(lead).map(value => 
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',')
    ).join('\n');
    return header + rows;
  };

  const downloadFile = (content, fileName, mimeType) => {
    const a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(new Blob([content], { type: mimeType }), fileName);
    } else {
      a.href = URL.createObjectURL(new Blob([content], { type: mimeType }));
      a.style.display = 'none';
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleBulkAssign = async () => {
    if (selectedLeads.length === 0 || !bulkAssignUser) {
      message.error('Please select leads and a user to assign');
      return;
    }

    try {
      await bulkAssignLeads({ lead_ids: selectedLeads, executive_id: bulkAssignUser });
      message.success('Leads assigned successfully');
      setBulkAssignUser('');
      onBulkAssign();
    } catch (error) {
      console.error('Error bulk assigning leads:', error);
      message.error('Failed to assign leads');
    }
  };

  return (
    <>
      {isAdmin && (
        <>
          <Select
            style={{ width: 200, marginRight: 10 }}
            placeholder="Select User to Assign"
            onChange={(value) => setBulkAssignUser(value)}
            value={bulkAssignUser}
          >
            {users.map(user => (
              <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
            ))}
          </Select>
          <Button onClick={handleBulkAssign} disabled={selectedLeads.length === 0 || !bulkAssignUser}>
            Bulk Assign
          </Button>
          <Button
            icon={<DownloadOutlined />}
            onClick={exportToCSV}
            disabled={loading}
            style={{ marginLeft: '10px' }}
          >
            Export to CSV
          </Button>
          <Button
            icon={<DownloadOutlined />}
            onClick={exportToXLSX}
            disabled={loading}
            style={{ marginLeft: '10px' }}
          >
            Export to XLSX
          </Button>
          <BulkDeleteLeads
            selectedLeads={selectedLeads}
            onDeleteComplete={onBulkDelete}
            disabled={loading || selectedLeads.length === 0}
          />
        </>
      )}
      {loading && <Spin size="small" style={{ marginLeft: '10px' }} />}
    </>
  );
};

export default LeadExportAndBulkActions;