import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Statistic, Spin, Alert, Typography, Button, Modal, Select, Divider, Collapse, Table } from 'antd';
import { UserOutlined, ReloadOutlined, DollarCircleOutlined, BarChartOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined, CloseCircleOutlined, TrophyOutlined, ArrowUpOutlined, ArrowDownOutlined, TeamOutlined } from '@ant-design/icons';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getDashboardData, getCurrentUserDetails } from '../apiService';

const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCoordinator, setSelectedCoordinator] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalStats, setModalStats] = useState(null);
    const [includeAdminLeads, setIncludeAdminLeads] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);

    const fetchDashboardData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const [userData, dashData] = await Promise.all([
                getCurrentUserDetails(),
                getDashboardData()
            ]);
            setCurrentUser(userData);
            setDashboardData(dashData);
        } catch (err) {
            console.error('Error fetching dashboard data:', err);
            setError('Failed to load dashboard data. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);

    const handleCoordinatorClick = (coordinator) => {
        setSelectedCoordinator(coordinator);
        setModalVisible(true);
        setModalStats(coordinator);
    };

    const renderRevenueComparison = () => {
        const { current_month, previous_month } = dashboardData;
        const data = [
            {
                name: 'Previous Month',
                'Generated Revenue': parseFloat(previous_month.generated_revenue),
                'Expected Revenue': parseFloat(previous_month.expected_revenue),
            },
            {
                name: 'Current Month',
                'Generated Revenue': parseFloat(current_month.generated_revenue),
                'Expected Revenue': parseFloat(current_month.expected_revenue),
            },
        ];

        return (
            <Card title="Revenue Comparison">
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Generated Revenue" fill="#82ca9d" />
                        <Bar dataKey="Expected Revenue" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderLeadComparisonGraph = () => {
        if (!dashboardData || !dashboardData.leads_admissions_comparison) {
            return <Alert message="Lead comparison data is not available" type="warning" />;
        }

        const { current_month, previous_month } = dashboardData.leads_admissions_comparison;
        const data = [
            {
                name: 'Previous Month',
                Leads: previous_month.leads,
                Admissions: previous_month.admissions,
            },
            {
                name: 'Current Month',
                Leads: current_month.leads,
                Admissions: current_month.admissions,
            },
        ];

        const leadChange = ((current_month.leads - previous_month.leads) / previous_month.leads) * 100;
        const admissionChange = ((current_month.admissions - previous_month.admissions) / previous_month.admissions) * 100;

        return (
            <Card title="Lead and Admission Comparison">
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Leads" fill="#8884d8" />
                        <Bar dataKey="Admissions" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
                <Divider />
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic
                            title="Lead Change"
                            value={leadChange}
                            precision={2}
                            valueStyle={{ color: leadChange >= 0 ? '#3f8600' : '#cf1322' }}
                            prefix={leadChange >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Admission Change"
                            value={admissionChange}
                            precision={2}
                            valueStyle={{ color: admissionChange >= 0 ? '#3f8600' : '#cf1322' }}
                            prefix={admissionChange >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Current Month Conversion Rate"
                            value={current_month.conversion_rate}
                            precision={2}
                            valueStyle={{ color: '#1890ff' }}
                            suffix="%"
                        />
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderCoordinatorComparisonTable = () => {
        if (!dashboardData || !dashboardData.coordinator_stats) {
            return <Alert message="Coordinator comparison data is not available" type="warning" />;
        }

        const columns = [
            {
                title: 'Coordinator',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Current Month Leads',
                dataIndex: 'current_leads',
                key: 'current_leads',
            },
            {
                title: 'Current Month Admissions',
                dataIndex: 'current_admissions',
                key: 'current_admissions',
            },
            {
                title: 'Current Conversion Rate',
                dataIndex: 'current_conversion_rate',
                key: 'current_conversion_rate',
                render: (text) => `${text}%`,
            },
            {
                title: 'Previous Month Leads',
                dataIndex: 'previous_leads',
                key: 'previous_leads',
            },
            {
                title: 'Previous Month Admissions',
                dataIndex: 'previous_admissions',
                key: 'previous_admissions',
            },
            {
                title: 'Previous Conversion Rate',
                dataIndex: 'previous_conversion_rate',
                key: 'previous_conversion_rate',
                render: (text) => `${text}%`,
            },
        ];

        return (
            <Card title="Coordinator Comparison">
                <Table 
                    dataSource={dashboardData.coordinator_stats} 
                    columns={columns} 
                    rowKey="id"
                    scroll={{ x: true }}
                />
            </Card>
        );
    };

    const renderCoordinatorPerformanceGraph = (stats) => {
        const data = [
            {
                name: 'Previous Month',
                Leads: parseInt(stats.previous_leads),
                Admissions: parseInt(stats.previous_admissions),
                'Conversion Rate': stats.previous_conversion_rate,
            },
            {
                name: 'Current Month',
                Leads: parseInt(stats.current_leads),
                Admissions: parseInt(stats.current_admissions),
                'Conversion Rate': stats.current_conversion_rate,
            },
        ];

        return (
            <Card title="Your Performance Comparison">
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="Leads" stroke="#8884d8" />
                        <Line yAxisId="left" type="monotone" dataKey="Admissions" stroke="#82ca9d" />
                        <Line yAxisId="right" type="monotone" dataKey="Conversion Rate" stroke="#ffc658" />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderCoordinatorStats = (stats) => (
        <Card className="coordinator-stats-card">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Total Admissions" 
                        value={stats.current_admissions} 
                        prefix={<TrophyOutlined style={{ color: '#722ed1' }} />}
                        valueStyle={{ color: '#722ed1', fontWeight: 'bold', fontSize: '24px' }}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Total Follow Ups" 
                        value={stats.total_follow_ups} 
                        prefix={<PhoneOutlined style={{ color: '#1890ff' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Demo Booked" 
                        value={stats.demo_booked} 
                        prefix={<CalendarOutlined style={{ color: '#52c41a' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Demo Done" 
                        value={stats.demo_done} 
                        prefix={<CheckCircleOutlined style={{ color: '#13c2c2' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Demo Missed" 
                        value={stats.demo_missed} 
                        prefix={<CloseCircleOutlined style={{ color: '#f5222d' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Closed" 
                        value={stats.closed} 
                        prefix={<CheckCircleOutlined style={{ color: '#faad14' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Total NP" 
                        value={stats.total_np} 
                        prefix={<UserOutlined style={{ color: '#722ed1' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Unconfirmed" 
                        value={stats.unconfirmed}
                        prefix={<TeamOutlined style={{ color: '#1890ff' }} />}
                    />
                </Col>
            </Row>
            <Divider />
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Statistic
                        title="Expected Revenue"
                        value={stats.expected_revenue}
                        precision={2}
                        prefix={<DollarCircleOutlined style={{ color: '#722ed1' }} />}
                        suffix="₹"
                    />
                </Col>
                <Col span={12}>
                    <Statistic
                        title="Generated Revenue"
                        value={stats.revenue_generated}
                        precision={2}
                        prefix={<DollarCircleOutlined style={{ color: '#13c2c2' }} />}
                        suffix="₹"
                    />
                </Col>
            </Row>
        </Card>
    );

    if (loading) return <Spin size="large" />;
    if (error) return <Alert message="Error" description={error} type="error" showIcon />;
    if (!dashboardData || !currentUser) return null;

    const isAdmin = currentUser.role === 'administrator' || currentUser.role === 'crm_admin';
    const userStats = dashboardData.coordinator_stats.find(coord => coord.id === currentUser.user_id);

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <Title level={2}>Dashboard</Title>
                <Button icon={<ReloadOutlined />} onClick={fetchDashboardData}>Refresh</Button>
            </div>

            {isAdmin ? (
                <>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Card>
                                <Statistic
                                    title="Total Leads"
                                    value={dashboardData.lead_stats.total_leads}
                                    prefix={<UserOutlined style={{ color: '#1890ff' }} />}
                                    valueStyle={{ color: '#1890ff' }}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card>
                                <Statistic
                                    title="Total Admissions"
                                    value={dashboardData.lead_stats.total_admissions}
                                    prefix={<TrophyOutlined style={{ color: '#722ed1' }} />}
                                    valueStyle={{ color: '#722ed1', fontWeight: 'bold', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card>
                                <Statistic
                                    title="Generated Revenue (Current Month)"
                                    value={dashboardData.current_month.generated_revenue}
                                    precision={2}
                                    prefix={<DollarCircleOutlined style={{ color: '#13c2c2' }} />}
                                    suffix="₹"
                                    valueStyle={{ color: '#13c2c2' }}
                                />
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} className="mt-6">
                    <Col xs={24} md={12}>
                            {renderLeadComparisonGraph()}
                        </Col>
                        <Col xs={24} md={12}>
                            {renderRevenueComparison()}
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} className="mt-6">
                        <Col xs={24}>
                            {renderCoordinatorComparisonTable()}
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={[16, 16]} className="mt-6">
                        <Col xs={24}>
                            <Card 
                                title="Coordinator Statistics" 
                                extra={
                                    <Select
                                        defaultValue={includeAdminLeads}
                                        style={{ width: 200 }}
                                        onChange={(value) => setIncludeAdminLeads(value)}
                                    >
                                        <Option value={true}>Include Admin Leads</Option>
                                        <Option value={false}>Exclude Admin Leads</Option>
                                    </Select>
                                }
                            >
                                <Collapse>
                                    {(includeAdminLeads ? dashboardData.coordinator_stats : dashboardData.coordinator_stats.filter(stat => stat.id !== 'admin')).map((coordinator) => (
                                        <Panel header={coordinator.name} key={coordinator.id}>
                                            {renderCoordinatorStats(coordinator)}
                                            <Button onClick={() => handleCoordinatorClick(coordinator)} style={{ marginTop: '16px' }}>
                                                View Detailed Stats
                                            </Button>
                                        </Panel>
                                    ))}
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                </>
            ) : (
                // Coordinator view
                userStats ? (
                  <Row gutter={[16, 16]}>
                      <Col xs={24}>
                          <Card title={`Your Statistics (${currentUser.display_name})`}>
                              {renderCoordinatorStats(userStats)}
                          </Card>
                      </Col>
                      <Col xs={24}>
                          {renderCoordinatorPerformanceGraph(userStats)}
                      </Col>
                      <Col xs={24}>
                          <Card title="Your Performance">
                              <Row gutter={[16, 16]}>
                                  <Col xs={24} sm={12}>
                                      <Statistic
                                          title="Current Month Leads"
                                          value={userStats.current_leads}
                                          prefix={<UserOutlined />}
                                      />
                                  </Col>
                                  <Col xs={24} sm={12}>
                                      <Statistic
                                          title="Previous Month Leads"
                                          value={userStats.previous_leads}
                                          prefix={<UserOutlined />}
                                      />
                                  </Col>
                                  <Col xs={24} sm={12}>
                                      <Statistic
                                          title="Current Month Admissions"
                                          value={userStats.current_admissions}
                                          prefix={<TrophyOutlined />}
                                      />
                                  </Col>
                                  <Col xs={24} sm={12}>
                                      <Statistic
                                          title="Previous Month Admissions"
                                          value={userStats.previous_admissions}
                                          prefix={<TrophyOutlined />}
                                      />
                                  </Col>
                                  <Col xs={24} sm={12}>
                                      <Statistic
                                          title="Current Conversion Rate"
                                          value={userStats.current_conversion_rate}
                                          precision={2}
                                          suffix="%"
                                          prefix={<BarChartOutlined />}
                                      />
                                  </Col>
                                  <Col xs={24} sm={12}>
                                      <Statistic
                                          title="Previous Conversion Rate"
                                          value={userStats.previous_conversion_rate}
                                          precision={2}
                                          suffix="%"
                                          prefix={<BarChartOutlined />}
                                      />
                                  </Col>
                              </Row>
                          </Card>
                      </Col>
                  </Row>
              ) : (
                  <Alert 
                      message="No Data Available" 
                      description="Your statistics are not available at the moment. Please contact an administrator if you believe this is an error." 
                      type="warning" 
                      showIcon 
                  />
              )
          )}

          <Modal
              title={`${selectedCoordinator?.name}'s Detailed Statistics`}
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
              width={800}
          >
              {modalLoading ? (
                  <Spin size="large" />
              ) : modalStats ? (
                  <>
                      {renderCoordinatorStats(modalStats)}
                      <Divider />
                      {renderCoordinatorPerformanceGraph(modalStats)}
                  </>
              ) : null}
          </Modal>
      </div>
  );
};

export default Dashboard;