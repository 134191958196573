import React, { useState } from 'react';
import { Button, Modal, Form, Input, DatePicker, TimePicker, Select, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createLead } from '../apiService';
import { formatDateForAPI, formatTimeForAPI } from '../utils/dateUtils';

const { Option } = Select;

const CreateLeadModal = ({ onLeadCreated }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCreate = async (values) => {
    try {
      const leadData = {
        ...values,
        booking_date: values.booking_date ? formatDateForAPI(values.booking_date) : null,
        booking_time: values.booking_time ? formatTimeForAPI(values.booking_time) : null,
      };
      await createLead(leadData);
      message.success('Lead created successfully');
      setVisible(false);
      form.resetFields();
      if (onLeadCreated) {
        onLeadCreated();
      }
    } catch (error) {
      console.error('Error creating lead:', error);
      message.error('Failed to create lead');
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
        Create New Lead
      </Button>
      <Modal
        visible={visible}
        title="Create New Lead"
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleCreate} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="course" label="Course">
            <Input />
          </Form.Item>
          <Form.Item name="booking_date" label="Booking Date">
            <DatePicker />
          </Form.Item>
          <Form.Item name="booking_time" label="Booking Time">
            <TimePicker format="HH:mm" />
          </Form.Item>
          <Form.Item name="demo_language" label="Demo Language">
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select>
              <Option value="Unconfirmed">Unconfirmed</Option>
              <Option value="Demo Booked">Demo Booked</Option>
              <Option value="Demo Done">Demo Done</Option>
              <Option value="Demo Missed">Demo Missed</Option>
              <Option value="Follow Up">Follow Up</Option>
              <Option value="Closed">Closed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="priority" label="Priority">
            <Select>
              <Option value="Low">Low</Option>
              <Option value="Medium">Medium</Option>
              <Option value="High">High</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Lead
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateLeadModal;