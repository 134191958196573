import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Menu, Dropdown, Avatar, Layout } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined, DashboardOutlined, BookOutlined, BarChartOutlined, MoreOutlined } from '@ant-design/icons';
import Notifications from './Notifications';
import styled from 'styled-components';

const { Header } = Layout;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const Logo = styled.img`
  height: 40px;
  margin-right: 20px;
`;

const NavMenu = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
`;

const NavItem = styled.li`
  padding: 0 15px;
  cursor: pointer;
`;

const NavLink = styled.span`
  color: #001529;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #1890ff;
  }
`;

const UserMenu = styled.div`
  display: flex;
  align-items: center;
`;

const Navbar = ({ logout, currentUser, fullyAuthenticated }) => {
  const [open, setOpen] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  const navRef = useRef(null);
  const navigate = useNavigate();

  const handleMenuClick = useCallback(async (e) => {
    if (e.key === 'logout') {
      logout();
    } else if (e.key === 'profile') {
      navigate(`/user/${currentUser.user_id}`);
    }
    setOpen(false);
  }, [logout, navigate, currentUser]);

  const handleOpenChange = useCallback((flag) => {
    setOpen(flag);
  }, []);

  const menuItems = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: 'View Profile',
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
    },
  ];

  const allNavItems = [
    { key: 'dashboard', label: 'Dashboard', icon: <DashboardOutlined />, link: '/dashboard' },
    { key: 'social', label: 'InConnect', link: '/social' },
    { key: 'leads', label: 'Leads', link: '/leads' },
    { key: 'reminders', label: 'Reminders', link: '/reminders' },
    { key: 'tasks', label: 'Tasks', link: '/tasks' },
    { key: 'canned-responses', label: 'Canned Responses', link: '/canned-responses' },
    { key: 'reports', label: 'Reports', icon: <BarChartOutlined />, link: '/reports' },
    { key: 'wiki', label: 'Wiki', icon: <BookOutlined />, link: '/wiki' },
  ];

  useEffect(() => {
    const updateVisibleItems = () => {
      if (navRef.current) {
        const navWidth = navRef.current.offsetWidth;
        let availableWidth = navWidth - 100; // Reserve some space for the "More" dropdown
        let visibleCount = 0;

        for (let i = 0; i < allNavItems.length; i++) {
          const item = allNavItems[i];
          const itemWidth = item.label.length * 10 + 40; // Estimate item width
          if (availableWidth - itemWidth > 0) {
            visibleCount++;
            availableWidth -= itemWidth;
          } else {
            break;
          }
        }

        setVisibleItems(allNavItems.slice(0, visibleCount));
        setHiddenItems(allNavItems.slice(visibleCount));
      }
    };

    updateVisibleItems();
    window.addEventListener('resize', updateVisibleItems);
    return () => window.removeEventListener('resize', updateVisibleItems);
  }, []);

  const renderNavItem = (item) => (
    <NavItem key={item.key} onClick={() => navigate(item.link)}>
      <NavLink>
        {item.icon && React.cloneElement(item.icon, { style: { marginRight: 5 } })}
        {item.label}
      </NavLink>
    </NavItem>
  );

  return (
    <StyledHeader>
      <Logo src="https://nationalinstituteoflanguage.in/wp-content/uploads/2024/09/InConnect-1.png" alt="InConnect Logo" onClick={() => navigate('/')} />
      <NavMenu ref={navRef}>
        {visibleItems.map(renderNavItem)}
        {hiddenItems.length > 0 && (
          <Dropdown
            menu={{
              items: hiddenItems.map(item => ({
                key: item.key,
                icon: item.icon,
                label: item.label,
                onClick: () => navigate(item.link),
              })),
            }}
            trigger={['click']}
          >
            <NavItem>
              <NavLink>
                <MoreOutlined /> More
              </NavLink>
            </NavItem>
          </Dropdown>
        )}
      </NavMenu>
      <UserMenu>
        <Notifications fullyAuthenticated={fullyAuthenticated} />
        <Dropdown 
          menu={{ items: menuItems, onClick: handleMenuClick }}
          open={open}
          onOpenChange={handleOpenChange}
          trigger={['click']}
        >
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: '20px' }}>
            <Avatar 
              src={currentUser.profile_image_url} 
              icon={<UserOutlined />}
            />
            <span style={{ marginLeft: '8px', color: '#001529' }}>
              {currentUser.display_name || 'User'}
            </span>
          </div>
        </Dropdown>
      </UserMenu>
    </StyledHeader>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    display_name: PropTypes.string,
    profile_image_url: PropTypes.string,
  }).isRequired,
  fullyAuthenticated: PropTypes.bool.isRequired,
};

export default React.memo(Navbar);