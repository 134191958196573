import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { List, Button, Input, Select, message, Modal } from 'antd';
import { WhatsAppOutlined, SendOutlined, CloseOutlined } from '@ant-design/icons';
import { getLeadStatusHistory, getCannedResponses, sendWebhookData, updateNote, deleteNote } from '../apiService';
import { formatDateTimeForDisplay } from '../utils/dateUtils';
import WebhookForm from './WebhookForm';
import styled from 'styled-components';

const { TextArea } = Input;
const { Option } = Select;

// Styled Components
const Container = styled.div`
  width: 400px;
  background: #f5f5f5;
  border-radius: 12px;
  padding: 16px;
`;

const ChatContainer = styled.div`
  height: 400px;
  overflow-y: auto;
  margin-bottom: 16px;
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
`;

const MessageBubble = styled.div`
  margin: 8px 0;
  max-width: 85%;
  margin-left: ${props => props.isNote ? 'auto' : '0'};
  position: relative;
  transition: all 0.2s;

  &:hover {
    .delete-button {
      opacity: 1;
    }
  }
`;

const BubbleContent = styled.div`
  background: ${props => props.isNote ? '#e6f4ff' : '#f0f0f0'};
  padding: 12px;
  border-radius: 12px;
  border-top-right-radius: ${props => props.isNote ? '4px' : '12px'};
  border-top-left-radius: ${props => !props.isNote ? '4px' : '12px'};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const DeleteButton = styled(CloseOutlined)`
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 12px;
  color: #ff4d4f;
  opacity: 0;
  transition: all 0.2s;
  cursor: pointer;
  padding: 4px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  
  &:hover {
    transform: scale(1.1);
    color: #ff1f1f;
  }
`;

const StatusMessage = styled.div`
  text-align: center;
  margin: 16px 0;
  color: #666;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.03);
  padding: 8px 12px;
  border-radius: 16px;
`;

const MessageTime = styled.div`
  font-size: 11px;
  color: #999;
  margin-top: 4px;
  text-align: ${props => props.isNote ? 'right' : 'left'};
`;

const MessageMeta = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const InputContainer = styled.div`
  margin-top: 16px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;
`;

const NotesPopover = ({ leadId, phone, addNote, notes, refreshNotes, leadName, coordinatorName }) => {
  // State management
  const [statusHistory, setStatusHistory] = useState([]);
  const [cannedResponses, setCannedResponses] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState('');
  const [newNote, setNewNote] = useState('');
  const [isWebhookModalVisible, setIsWebhookModalVisible] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  
  // Refs
  const editInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  // Data fetching
  const fetchStatusHistory = useCallback(async () => {
    try {
      const historyResponse = await getLeadStatusHistory(leadId);
      setStatusHistory(Array.isArray(historyResponse) ? historyResponse : []);
    } catch (error) {
      console.error('Error fetching status history:', error);
      message.error('Failed to load status history');
    }
  }, [leadId]);

  const fetchCannedResponses = useCallback(async () => {
    try {
      const responses = await getCannedResponses();
      setCannedResponses(Array.isArray(responses) ? responses : []);
    } catch (error) {
      console.error('Error fetching canned responses:', error);
      message.error('Failed to load canned responses');
    }
  }, []);

  // Effects
  useEffect(() => {
    fetchStatusHistory();
    fetchCannedResponses();
  }, [fetchStatusHistory, fetchCannedResponses]);

  useEffect(() => {
    if (editingNote && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [editingNote]);

  useEffect(() => {
    // Scroll to bottom when new messages arrive
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [notes, statusHistory]);

  // Event handlers
  const handleAddNote = async (e) => {
    e.preventDefault();
    if (!newNote.trim()) {
      message.error('Note content cannot be empty');
      return;
    }
    try {
      await addNote(leadId, newNote);
      setNewNote('');
      refreshNotes();
      message.success('Note added successfully');
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  const handleStartEdit = (note) => {
    setEditingNote(note.id);
    setEditedContent(note.note_content);
  };

  const handleFinishEdit = async () => {
    if (editingNote && editedContent.trim() !== '') {
      try {
        await updateNote(leadId, editingNote, editedContent);
        refreshNotes();
        message.success('Note updated successfully');
      } catch (error) {
        console.error('Error updating note:', error);
        message.error('Failed to update note');
      }
    }
    setEditingNote(null);
    setEditedContent('');
  };

  const handleDeleteNote = async (noteId, e) => {
    e.stopPropagation();
    try {
      await deleteNote(leadId, noteId);
      refreshNotes();
      message.success('Note deleted successfully');
    } catch (error) {
      console.error('Error deleting note:', error);
      message.error('Failed to delete note');
    }
  };

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(selectedResponse);
    window.open(`https://web.whatsapp.com/send?phone=${phone}&text=${message}`, '_blank');
  };

  const handleWebhookSubmit = async (webhookData) => {
    try {
      await sendWebhookData(webhookData);
      message.success('Data sent to webhook successfully');
      setIsWebhookModalVisible(false);
    } catch (error) {
      console.error('Error sending data to webhook:', error);
      message.error('Failed to send data to webhook');
    }
  };

  // Data preparation
  const combinedData = [
    ...notes.map(note => ({ ...note, type: 'note' })),
    ...statusHistory.map(status => ({ ...status, type: 'status' }))
  ].sort((a, b) => new Date(b.created_at || b.changed_at) - new Date(a.created_at || a.changed_at));

  // Render functions
  const renderMessage = (item) => {
    if (item.type === 'status') {
      return (
        <StatusMessage>
          {item.changed_by || 'System'} changed status from{' '}
          <strong>{item.old_status || 'Unknown'}</strong> to{' '}
          <strong>{item.new_status}</strong>
          <MessageTime isNote={false}>
            {formatDateTimeForDisplay(item.changed_at)}
          </MessageTime>
        </StatusMessage>
      );
    }

    return (
      <MessageBubble
        isNote={true}
        onClick={() => !editingNote && handleStartEdit(item)}
      >
        {editingNote === item.id ? (
          <TextArea
            ref={editInputRef}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            onBlur={handleFinishEdit}
            onPressEnter={(e) => {
              if (!e.shiftKey) {
                e.preventDefault();
                handleFinishEdit();
              }
            }}
            autoFocus
            style={{ width: '100%', border: 'none', borderRadius: '8px' }}
          />
        ) : (
          <BubbleContent isNote={true}>
            <DeleteButton
              className="delete-button"
              onClick={(e) => handleDeleteNote(item.id, e)}
            />
            <MessageMeta>{item.created_by_name || 'Unknown'}</MessageMeta>
            <div>{item.note_content}</div>
            <MessageTime isNote={true}>
              {formatDateTimeForDisplay(item.created_at)}
            </MessageTime>
          </BubbleContent>
        )}
      </MessageBubble>
    );
  };

  return (
    <Container>
      <ChatContainer ref={chatContainerRef}>
        <List
          dataSource={combinedData}
          renderItem={renderMessage}
          split={false}
        />
      </ChatContainer>

      <InputContainer>
        <form onSubmit={handleAddNote}>
          <TextArea
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            rows={4}
            placeholder="Enter new note..."
            style={{ marginBottom: '8px', borderRadius: '8px' }}
          />
          <Button type="primary" htmlType="submit" block>
            Add Note
          </Button>
        </form>

        <Select
          style={{ width: '100%', marginTop: '16px' }}
          placeholder="Select canned response"
          onChange={setSelectedResponse}
          value={selectedResponse}
        >
          {cannedResponses.map(response => (
            <Option key={response.id} value={response.content}>
              {response.title}
            </Option>
          ))}
        </Select>

        <ActionButtons>
          <Button
            type="primary"
            icon={<WhatsAppOutlined />}
            onClick={handleWhatsAppClick}
            disabled={!selectedResponse}
            style={{ flex: 1 }}
          >
            Send WhatsApp
          </Button>

          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={() => setIsWebhookModalVisible(true)}
            style={{ flex: 1 }}
          >
            Send for allotment
          </Button>
        </ActionButtons>
      </InputContainer>

      <Modal
        title="Allotment form"
        visible={isWebhookModalVisible}
        onCancel={() => setIsWebhookModalVisible(false)}
        footer={null}
      >
        <WebhookForm
          leadName={leadName}
          phone={phone}
          coordinatorName={coordinatorName}
          onSubmit={handleWebhookSubmit}
        />
      </Modal>
    </Container>
  );
};

NotesPopover.propTypes = {
  leadId: PropTypes.number.isRequired,
  phone: PropTypes.string.isRequired,
  addNote: PropTypes.func.isRequired,
  notes: PropTypes.array.isRequired,
  refreshNotes: PropTypes.func.isRequired,
  leadName: PropTypes.string.isRequired,
  coordinatorName: PropTypes.string,
};

export default NotesPopover;