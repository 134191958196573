import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Select, DatePicker, TimePicker, message, Button, Tag, Skeleton, Spin, Modal, List, Row, Col, Checkbox, Popover } from 'antd';
import { EditOutlined, DeleteOutlined, UserOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { getLeads, updateLead, addNote, bulkAssignLeads, getAllUsers, createLead, deleteLead, getUserDetailsById } from '../apiService';
import NotesPopover from './NotesPopover';
import LeadExportAndBulkActions from './LeadExportAndBulkActions';
import CreateLeadModal from './CreateLeadModal';
import BulkDeleteLeads from './BulkDeleteLeads';




import { 
  formatDateForAPI, 
  formatTimeForAPI, 
  formatDateTimeForAPI, 
  formatDateForDisplay,
  formatTimeForDisplay,
  formatDateTimeForDisplay,
  parseDateFromAPI,
  parseTimeFromAPI,
  parseDateTimeFromAPI,
  isValidDate,
  isValidTime
} from '../utils/dateUtils';

const AssignmentHistoryPopover = ({ history, userNames, fetchUserName }) => {
  useEffect(() => {
    history.forEach(item => {
      if (item.assigned_from) fetchUserName(item.assigned_from);
      fetchUserName(item.assigned_to);
    });
  }, [history, fetchUserName]);

  return (
    <List
      dataSource={history}
      renderItem={(item, index) => (
        <List.Item>
          {index === 0 ? 'Current: ' : index === 1 ? 'Last: ' : `Previous (${history.length - index}): `}
          {item.assigned_from 
            ? `${userNames[item.assigned_from] || 'Loading...'} → `
            : 'New Assignment: '}
          {userNames[item.assigned_to] || 'Loading...'} 
          ({moment(item.assigned_at).format('D-MMM-YYYY HH:mm')})
        </List.Item>
      )}
    />
  );
};


const { Option } = Select;
const { RangePicker } = DatePicker;

const LeadsManagementPage = ({ currentUser }) => {
  const [leads, setLeads] = useState([]);
  const [closedAndAdmissionLeads, setClosedAndAdmissionLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [demoLanguageModalVisible, setDemoLanguageModalVisible] = useState(false);
  const [editingLead, setEditingLead] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    per_page: 20,
    search: '',
    status: '',
    date_from: null,
    date_to: null,
    coordinator_id: currentUser && currentUser.role === 'crm_executive' ? currentUser.user_id : '',
    booking_date: null,
    booking_time: null,
    priority: '',
    assigned_from: '',
    assigned_to: '',
    assigned_at_from: null,
    assigned_at_to: null,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [users, setUsers] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [bulkAssignUser, setBulkAssignUser] = useState('');
  const [leadsLoaded, setLeadsLoaded] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [coordinatorModalVisible, setCoordinatorModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [userError, setUserError] = useState(null);
  const [closedAdmissionModalVisible, setClosedAdmissionModalVisible] = useState(false);
  const [userNames, setUserNames] = useState({});
  const isAdmin = currentUser.role === 'administrator';
  const isCrmExecutive = currentUser.role === 'crm_executive';

  const timeOptions = [
    '11:00 AM', '04:00 PM', '08:00 PM', '09:00 AM', '10:00 AM', '12:00 PM',
    '02:00 PM', '03:00 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM',
    '07:00 PM', '07:30 PM', '08:30 PM', '09:00 PM', '09:30 PM', '10:00 PM'
  ];

  const fetchUserName = useCallback(async (userId) => {
    if (!userNames[userId]) {
      try {
        const userDetails = await getUserDetailsById(userId);
        setUserNames(prev => ({
          ...prev,
          [userId]: userDetails.display_name
        }));
      } catch (error) {
        console.error(`Error fetching user details for ID ${userId}:`, error);
        setUserNames(prev => ({
          ...prev,
          [userId]: 'Unknown'
        }));
      }
    }
  }, [userNames]);

  const getLanguageColor = (language) => {
    switch (language) {
      case 'Hindi':
        return 'green';
      case 'English':
        return 'red';
      default:
        return 'yellow';
    }
  };

  

  const handleDemoLanguageEdit = (lead) => {
    setEditingLead(lead);
    setDemoLanguageModalVisible(true);
  };

  const handleDemoLanguageChange = async (value) => {
    try {
      await handleCellEdit(editingLead.id, 'demo_language', value);
      setDemoLanguageModalVisible(false);
      setEditingLead(null);
    } catch (error) {
      console.error('Error updating demo language:', error);
      message.error('Failed to update demo language');
    }
  };

  const fetchLeads = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Current filters:', filters);
  
      const formattedFilters = {
        page: filters.page,
        per_page: filters.per_page,
        search: filters.search || undefined,
        status: filters.status || undefined,
        date_from: filters.date_from ? formatDateForAPI(filters.date_from) : undefined,
        date_to: filters.date_to ? formatDateForAPI(filters.date_to) : undefined,
        booking_date: filters.booking_date ? formatDateForAPI(filters.booking_date) : undefined,
        booking_time: filters.booking_time || undefined,
        priority: filters.priority || undefined,
        coordinator_id: currentUser.role === 'crm_executive' ? currentUser.user_id : filters.coordinator_id,
        assigned_from: filters.assigned_from || undefined,
        assigned_to: filters.assigned_to || undefined,
        assigned_at_from: filters.assigned_at_from ? formatDateForAPI(filters.assigned_at_from) : undefined,
        assigned_at_to: filters.assigned_at_to ? formatDateForAPI(filters.assigned_at_to) : undefined, 
        
      };
  
      console.log('Formatted filters:', formattedFilters);
  
      const cleanFilters = Object.fromEntries(
        Object.entries(formattedFilters).filter(([_, v]) => v !== undefined && v !== '')
      );
  
      console.log('Clean filters:', cleanFilters);
  
      const response = await getLeads(cleanFilters);
      console.log('API response:', response);
      
      const leadsWithDetails = response.leads.map((lead, index) => ({
        ...lead,
        number: index + 1 + (filters.page - 1) * filters.per_page,
        booking_date: lead.booking_date ? formatDateForDisplay(lead.booking_date) : null,
        booking_time: lead.booking_time ? formatTimeForDisplay(lead.booking_time) : null,
        call_date: lead.call_date ? formatCallDateTimeForDisplay(lead.call_date) : null,
        next_call_date: lead.next_call_date ? formatCallDateTimeForDisplay(lead.next_call_date) : null,
        recentlyUpdated: isRecentlyUpdated(lead),
        notUpdated: isNotUpdated(lead),
        assignment_history: lead.assignment_history || [],
      }));

      if (isCrmExecutive) {
        const filteredLeads = leadsWithDetails.filter(lead => lead.status !== 'Closed' && lead.status !== 'Admission');
        const closedAndAdmissionLeads = leadsWithDetails.filter(lead => lead.status === 'Closed' || lead.status === 'Admission');
        setLeads(filteredLeads);
        setClosedAndAdmissionLeads(closedAndAdmissionLeads);
      } else {
        setLeads(leadsWithDetails);
      }

      setTotalPages(response.pages);
      setLeadsLoaded(true);
    } catch (error) {
      console.error('Error fetching leads:', error);
      setError('Failed to load leads. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [filters, currentUser.role, currentUser.user_id, isCrmExecutive]);

  const fetchUsers = useCallback(async () => {
    try {
      setUserError(null);
      const response = await getAllUsers();
      if (response && Array.isArray(response.users)) {
        setUsers(response.users);
        setUsersLoaded(true);
      } else {
        throw new Error('Unexpected user data format');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setUserError(error.message || 'Failed to load users');
      setUsersLoaded(true);
    }
  }, []);

  useEffect(() => {
    fetchLeads();
    fetchUsers();
  }, [fetchLeads, fetchUsers]);

  const handleFilterChange = (name, value) => {
    setFilters(prev => {
      const newFilters = { ...prev, [name]: value, page: 1 };
  
      // Special handling for coordinator filter
      if (name === 'coordinator_id') {
        if (currentUser.role === 'crm_executive') {
          newFilters.coordinator_id = currentUser.user_id;  // Always use user_id for CRM executives
        } else {
          // For administrators, handle 'unassigned' and 'all' cases
          if (value === 'unassigned') {
            newFilters.coordinator_id = 'null';
          } else if (value === 'all') {
            newFilters.coordinator_id = '';
          }
          // For specific coordinator IDs, we keep the value as is
        }
      }
      // Special handling for date range
      if (name === 'date_range') {
        if (value && value.length === 2) {
          newFilters.date_from = value[0].format('YYYY-MM-DD');
          newFilters.date_to = value[1].format('YYYY-MM-DD');
        } else {
          delete newFilters.date_from;
          delete newFilters.date_to;
        }
      }
      if (name === 'booking_date') {
        if (value) {
          // Store the date as a string in YYYY-MM-DD format
          newFilters.booking_date = moment(value).format('YYYY-MM-DD');
        } else {
          newFilters.booking_date = null;
        }
      }
  
      console.log(`Filter changed: ${name}`, newFilters);
      return newFilters;
    });
  };

  const formatCallDateTimeForDisplay = (dateTime) => {
    return moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format('D-MMM-YYYY hh:mm A');
  };

  const parseCallDateTimeForAPI = (dateTime) => {
    return moment(dateTime, 'D-MMM-YYYY hh:mm A').format('YYYY-MM-DD HH:mm:00');
  };

  const isRecentlyUpdated = (lead) => {
    const lastNote = lead.notes[0];
    const lastStatusChange = lead.status_history[0];
    const lastUpdateTime = Math.max(
      lastNote ? moment(lastNote.created_at).valueOf() : 0,
      lastStatusChange ? moment(lastStatusChange.changed_at).valueOf() : 0
    );
    const now = moment().valueOf();
    const hoursSinceLastUpdate = (now - lastUpdateTime) / (1000 * 60 * 60);
    return hoursSinceLastUpdate <= 3;
  };

  const isNotUpdated = (lead) => {
    const lastNote = lead.notes[0];
    const lastStatusChange = lead.status_history[0];
    const lastUpdateTime = Math.max(
      lastNote ? moment(lastNote.created_at).valueOf() : 0,
      lastStatusChange ? moment(lastStatusChange.changed_at).valueOf() : 0
    );
    const now = moment().valueOf();
    const hoursSinceLastUpdate = (now - lastUpdateTime) / (1000 * 60 * 60);
    return hoursSinceLastUpdate > 72;
  };

  const handleCellEdit = async (key, dataIndex, value) => {
    try {
      let updatedValue = value;
  
      switch (dataIndex) {
        case 'booking_date':
          updatedValue = value ? moment(value, 'DD-MMM-YYYY').format('YYYY-MM-DD') : null;
          break;
        case 'booking_time':
          updatedValue = value ? moment(value, 'hh:mm A').format('HH:mm:00') : null;
          break;
        case 'call_date':
        case 'next_call_date':
          updatedValue = value ? parseCallDateTimeForAPI(value) : null;
          break;
        case 'demo_language':
          // No need for special formatting
          break;
        default:
          // For other fields, use the value as is
          break;
      }
  
      const payload = {
        [dataIndex]: updatedValue,
      };
  
      await updateLead(key, payload);
  
      setLeads(prevLeads =>
        prevLeads.map(lead =>
          lead.id === key
            ? {
                ...lead,
                [dataIndex]: (() => {
                  switch (dataIndex) {
                    case 'booking_date':
                      return updatedValue ? moment(updatedValue).format('D-MMM-YYYY') : null;
                    case 'booking_time':
                      return updatedValue ? moment(updatedValue, 'HH:mm:ss').format('hh:mm A') : null;
                    case 'call_date':
                    case 'next_call_date':
                      return updatedValue ? formatCallDateTimeForDisplay(updatedValue) : null;
                    default:
                      return updatedValue;
                  }
                })(),
              }
            : lead
        )
      );
  
      message.success('Lead updated successfully');
    } catch (error) {
      console.error('Error updating lead:', error);
      message.error('Failed to update lead: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleAddNote = async (leadId, noteContent) => {
    try {
      await addNote(leadId, noteContent);
      message.success('Note added successfully');
      setLeads(prevLeads => prevLeads.map(lead => 
        lead.id === leadId 
          ? { 
              ...lead, 
              notes: [{ note_content: noteContent, created_at: new Date().toISOString() }, ...lead.notes],
              recentlyUpdated: true, 
              notUpdated: false 
            }
          : lead
      ));
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  const handleBulkAssign = async () => {
    if (selectedLeads.length === 0 || !bulkAssignUser) {
      return;
    }

    try {
      const data = { 
        lead_ids: selectedLeads, 
        executive_id: bulkAssignUser
      };
      await bulkAssignLeads(data);
      message.success('Leads assigned successfully');
      fetchLeads(); // Refresh leads after bulk assign
      setSelectedLeads([]);
      setBulkAssignUser('');
    } catch (error) {
      console.error('Error bulk assigning leads:', error);
      message.error('Failed to assign leads: ' + (error.response?.data?.message || error.message));
    }
  };

  const handleBulkDelete = () => {
    fetchLeads();
    setSelectedLeads([]);
  };

  const handleCoordinatorAssign = async (coordinatorId) => {
    try {
      const leadToUpdate = leads.find(lead => lead.id === selectedLead.id);
      if (!leadToUpdate) {
        throw new Error('Lead not found');
      }

      const payload = {
        coordinator_id: coordinatorId
      };

      await updateLead(selectedLead.id, payload);
      message.success('Coordinator assigned successfully');
      setCoordinatorModalVisible(false);
      
      // Refresh leads after assigning coordinator
      fetchLeads();
    } catch (error) {
      console.error('Error assigning coordinator:', error);
      message.error('Failed to assign coordinator');
    }
  };

  const handleCreateLead = async (values) => {
    try {
      const response = await createLead(values);
      message.success('Lead created successfully');
      fetchLeads();
      return response;
    } catch (error) {
      console.error('Error creating lead:', error);
      message.error('Failed to create lead');
      throw error;
    }
  };

  const handleDeleteLead = async (id) => {
    try {
      await deleteLead(id);
      message.success('Lead deleted successfully');
      fetchLeads();
    } catch (error) {
      console.error('Error deleting lead:', error);
      message.error('Failed to delete lead');
    }
  };

  const getStatusTag = (status) => {
    const statusStyles = {
      'Unconfirmed': { color: 'black', background: '#ffcccb' },
      'Demo Booked': { color: 'black', background: '#90EE90' },
      'Demo Done': { color: 'white', background: '#006400' },
      'Demo Missed': { color: 'white', background: '#FFA500' },
      'Follow Up': { color: 'black', background: '#FFFF00' },
      'Details Shared': { color: 'black', background: '#ADD8E6' },
      'Offer Given': { color: 'black', background: '#FFC0CB' },
      'For Offer': { color: 'white', background: '#8A2BE2' },
      'NP1': { color: 'white', background: '#00008B' },
      'NP2': { color: 'white', background: '#00008B' },
      'NP3': { color: 'white', background: '#00008B' },
      'Closed': { color: 'white', background: '#8B0000' },
      'Future Follow': { color: 'white', background: '#008B8B' },
      'Admission': { color: 'white', background: '#006400' }
    };

    const style = statusStyles[status] || { color: 'black', background: 'white' };
    return <Tag color={style.background} style={{ color: style.color }}>{status}</Tag>;
  };

  const handleLeadSelection = (selectedRowKeys) => {
    setSelectedLeads(selectedRowKeys);
  };

  const refreshLeads = () => {
    fetchLeads();
    setSelectedLeads([]);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 250,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Input
            defaultValue={text}
            onPressEnter={(e) => handleCellEdit(record.id, 'name', e.target.value)}
            onBlur={(e) => handleCellEdit(record.id, 'name', e.target.value)}
            style={{ width: '60%' }}
          />
          <Tag
            color={getLanguageColor(record.demo_language)}
            style={{ cursor: 'pointer' }}
            onClick={() => handleDemoLanguageEdit(record)}
          >
            {record.demo_language || '-'}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      fixed: 'left',
      width: 170,
      render: (text, record) => (
        <Input
          defaultValue={text}
          onPressEnter={(e) => handleCellEdit(record.id, 'phone', e.target.value)}
          onBlur={(e) => handleCellEdit(record.id, 'phone', e.target.value)}
        />
      ),
    },
    {
      title: 'Course',
      dataIndex: 'course',
      key: 'course',
      width: 300,
      render: (text, record) => (
        <Input
        defaultValue={text}
          onPressEnter={(e) => handleCellEdit(record.id, 'course', e.target.value)}
          onBlur={(e) => handleCellEdit(record.id, 'course', e.target.value)}
        />
      ),
    },
    {
      title: 'Booking Date',
      dataIndex: 'booking_date',
      key: 'booking_date',
      width: 180,
      render: (text, record) => (
        <DatePicker
          value={text ? moment(text, 'DD-MMM-YYYY') : null}
          format="DD-MMM-YYYY"
          onChange={(date) => handleCellEdit(record.id, 'booking_date', date ? date.format('DD-MMM-YYYY') : null)}
          allowClear
        />
      ),
    },
    {
      title: 'Booking Time',
      dataIndex: 'booking_time',
      key: 'booking_time',
      width: 150,
      render: (text, record) => (
        <Select
          value={text || undefined}
          style={{ width: 150 }}
          onChange={(value) => handleCellEdit(record.id, 'booking_time', value)}
          allowClear
        >
          {timeOptions.map(time => (
            <Option key={time} value={time}>{time}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Call Date',
      dataIndex: 'call_date',
      key: 'call_date',
      width: 230,
      render: (text, record) => (
        <DatePicker
          showTime={{ format: 'hh:mm A', use12Hours: true }}
          format="D-MMM-YYYY hh:mm A"
          value={text ? moment(text, 'D-MMM-YYYY hh:mm A') : null}
          onChange={(date, dateString) => handleCellEdit(record.id, 'call_date', dateString)}
          allowClear
        />
      ),
    },
    {
      title: 'Next Call Date',
      dataIndex: 'next_call_date',
      key: 'next_call_date',
      width: 230,
      render: (text, record) => (
        <DatePicker
          showTime={{ format: 'hh:mm A', use12Hours: true }}
          format="D-MMM-YYYY hh:mm A"
          value={text ? moment(text, 'D-MMM-YYYY hh:mm A') : null}
          onChange={(date, dateString) => handleCellEdit(record.id, 'next_call_date', dateString)}
          allowClear
        />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (text, record) => (
        <Select
          value={text || undefined}
          style={{ width: 150 }}
          onChange={(value) => handleCellEdit(record.id, 'status', value)}
          allowClear
        >
          {['Unconfirmed', 'Demo Booked', 'Demo Done', 'Demo Missed', 'Follow Up', 'Details Shared', 'Offer Given', 'For Offer', 'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'].map(status => (
           <Option key={status} value={status}>{getStatusTag(status)}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Coordinator',
      dataIndex: 'coordinator_name',
      key: 'coordinator_name',
      width: 150,
      render: (text, record) => (
        <Button 
          type="link" 
          onClick={() => {
            setSelectedLead(record);
            setCoordinatorModalVisible(true);
          }}
        >
          {text || 'Unassigned'}
        </Button>
      ),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 100,
      render: (text, record) => (
        <Select
          value={text || undefined}
          style={{ width: 100 }}
          onChange={(value) => handleCellEdit(record.id, 'priority', value)}
          allowClear
        >
          {['Low', 'Medium', 'High'].map(priority => (
            <Option key={priority} value={priority}>{priority}</Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      width: 150,
      render: (text, record) => (
        <Input
          defaultValue={text ? text.replace('₹', '') : ''}
          prefix="₹"
          onPressEnter={(e) => handleCellEdit(record.id, 'revenue', e.target.value)}
          onBlur={(e) => handleCellEdit(record.id, 'revenue', e.target.value)}
        />
      ),
    },
    {
      title: 'Assignment History',
      dataIndex: 'assignment_history',
      key: 'assignment_history',
      width: 250,
      render: (history, record) => {
        if (!isAdmin) return null;
        
        const currentAssignment = history && history.length > 0 ? history[0] : null;
        const lastAssignment = currentAssignment && currentAssignment.assigned_from 
          ? { assigned_to: currentAssignment.assigned_from, assigned_at: currentAssignment.assigned_at }
          : null;
        
        return (
          <div>
            <span>
              {lastAssignment 
                ? `Last assigned to: ${userNames[lastAssignment.assigned_to] || 'Loading...'} (${moment(lastAssignment.assigned_at).format('D-MMM-YYYY')})`
                : currentAssignment
                  ? 'First assignment'
                  : 'Never assigned'}
            </span>
            {history && history.length > 0 && (
              <Popover
                content={<AssignmentHistoryPopover history={history} userNames={userNames} fetchUserName={fetchUserName} />}
                title="Full Assignment History"
                trigger="click"
              >
                <Button type="link" size="small" style={{ marginLeft: '8px' }}>
                  View History ({history.length})
                </Button>
              </Popover>
            )}
          </div>
        );
      },
    },
    
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: 450,
      render: (notes, record) => {
        const lastNote = notes.length > 0 ? notes[0].note_content : 'No notes';
        return (
          <Popover
            content={
              <NotesPopover 
                leadId={Number(record.id)}
                phone={record.phone} 
                addNote={handleAddNote}
                notes={notes}
                statusHistory={record.status_history}
                refreshNotes={() => fetchLeads()}
                leadName={record.name}
                coordinatorName={record.coordinator_name}
              />
            }
            title="Notes and Logs"
            trigger="click"
          >
            <div style={{ cursor: 'pointer' }}>{lastNote}</div>
          </Popover>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedLeads,
    onChange: handleLeadSelection,
  };

  return (
    <div className="w-full px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Leads Management</h1>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      {userError && (
        <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded mb-4" role="alert">
          <strong className="font-bold">User Error:</strong>
          <span className="block sm:inline"> {userError}</span>
        </div>
      )}
      <Row gutter={[16, 16]} className="mb-6">
        <Col>
          <Input
            placeholder="Search leads"
            value={filters.search}
            onChange={(e) => handleFilterChange('search', e.target.value)}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Select
            style={{ width: 150 }}
            placeholder="Status"
            value={filters.status}
            onChange={(value) => handleFilterChange('status', value)}
          >
            <Option value="">All</Option>
            {['Unconfirmed', 'Demo Booked', 'Demo Done', 'Demo Missed', 'Follow Up', 'Details Shared', 'Offer Given', 'For Offer', 'NP1', 'NP2', 'NP3', 'Closed', 'Future Follow', 'Admission'].map(status => (
              <Option key={status} value={status}>{status}</Option>
            ))}
          </Select>
        </Col>
        <Col>
          <RangePicker
            style={{ width: 300 }}
            value={[
              filters.date_from ? moment(filters.date_from) : null,
              filters.date_to ? moment(filters.date_to) : null
            ]}
            onChange={(dates) => handleFilterChange('date_range', dates)}
            format="D-MMM-YYYY"
          />
        </Col>
        {isAdmin && (
          <Col>
            <Select
              style={{ width: 200 }}
              placeholder="Coordinator"
              value={filters.coordinator_id === 'null' ? 'unassigned' : (filters.coordinator_id === '' ? 'all' : filters.coordinator_id)}
              onChange={(value) => {
                console.log('Coordinator selected:', value);
                handleFilterChange('coordinator_id', value);
              }}
            >
              <Option value="all">All</Option>
              <Option value="unassigned">Unassigned</Option>
              {users.map(user => (
                <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
              ))}
            </Select>
          </Col>
        )}
        <Col>
          <DatePicker
            value={filters.booking_date ? moment(filters.booking_date, 'YYYY-MM-DD') : null}
            format="D-MMM-YYYY"
            onChange={(date) => handleFilterChange('booking_date', date ? date.format('YYYY-MM-DD') : null)}
          />
        </Col>
        <Col>
          <Select
            style={{ width: 150 }}
            placeholder="Demo Time"
            value={filters.booking_time}
            onChange={(value) => handleFilterChange('booking_time', value)}
          >
            <Option value="">All</Option>
            {timeOptions.map(time => (
              <Option key={time} value={time}>{time}</Option>
            ))}
          </Select>
        </Col>
    
        <Col>
          <Select
            style={{ width: 150 }}
            placeholder="Priority"
            value={filters.priority}
            onChange={(value) => handleFilterChange('priority', value)}
          >
            <Option value="">All</Option>
            <Option value="Low">Low</Option>
            <Option value="Medium">Medium</Option>
            <Option value="High">High</Option>
          </Select>
        </Col>
        <Col>
          <Button type="primary" onClick={fetchLeads}>Apply Filters</Button>
        </Col>
        <Col>
          <Button onClick={() => {
            setFilters({
              page: 1,
              per_page: 20,
              search: '',
              status: '',
              date_from: null,
              date_to: null,
              coordinator_id: currentUser.role === 'crm_executive' ? currentUser.id : '',
              booking_date: null,
              booking_time: null,
              priority: '',
            });
            fetchLeads();
          }}>Clear Filters</Button>
        </Col>
        <Col>
          <CreateLeadModal onLeadCreated={fetchLeads} />
        </Col>
        {isCrmExecutive && (
          <Col>
            <Button onClick={() => setClosedAdmissionModalVisible(true)}>
              Closed/Admission Leads
            </Button>
          </Col>
        )}
        <Col>
          <Button icon={<ReloadOutlined />} onClick={refreshLeads}>
            Refresh
          </Button>
        </Col>
        {isAdmin && (
          <Col>
            <LeadExportAndBulkActions
              filters={filters}
              selectedLeads={selectedLeads}
              users={users}
              onBulkAssign={handleBulkAssign}
              onBulkDelete={handleBulkDelete}
              isAdmin={isAdmin}
            />
          </Col>
        )}
      </Row>
      
      <Skeleton loading={!leadsLoaded || !usersLoaded} active>
        <Table
          dataSource={leads}
          columns={columns}
          rowKey="id"
          rowSelection={rowSelection}
          pagination={{
            total: totalPages * filters.per_page,
            current: filters.page,
            pageSize: filters.per_page,
            onChange: (page) => setFilters(prev => ({ ...prev, page })),
          }}
          loading={loading}
          scroll={{ x: 'max-content' }}
          rowClassName={(record) => {
            if (record.recentlyUpdated) {
              return 'bg-green-100';
            } else if (record.notUpdated) {
              return 'bg-red-100';
            }
            return '';
          }}
        />
      </Skeleton>

      <Modal
        title="Assign Coordinator"
        visible={coordinatorModalVisible}
        onCancel={() => setCoordinatorModalVisible(false)}
        footer={null}
      >
        <List
          dataSource={users}
          renderItem={user => (
            <List.Item
              key={user.id}
              onClick={() => handleCoordinatorAssign(user.id)}
              style={{ cursor: 'pointer' }}
            >
              {user.display_name || user.username}
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        title="Closed/Admission Leads"
        visible={closedAdmissionModalVisible}
        onCancel={() => setClosedAdmissionModalVisible(false)}
        width={1000}
        footer={null}
      >
        <Table
          dataSource={closedAndAdmissionLeads}
          columns={columns}
          rowKey="id"
          pagination={{
            pageSize: 10,
            total: closedAndAdmissionLeads.length,
          }}
          scroll={{ x: 'max-content' }}
        />
      </Modal>
      <Modal
  title="Edit Demo Language"
  visible={demoLanguageModalVisible}
  onCancel={() => setDemoLanguageModalVisible(false)}
  footer={null}
>
  <Select
    style={{ width: '100%' }}
    value={editingLead?.demo_language}
    onChange={handleDemoLanguageChange}
  >
    <Select.Option value="Hindi">Hindi</Select.Option>
    <Select.Option value="English">English</Select.Option>
    <Select.Option value="">None</Select.Option>
  </Select>
</Modal>
    </div>
  );
};

LeadsManagementPage.propTypes = {
  currentUser: PropTypes.shape({
    user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    username: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    date_of_birth: PropTypes.string,
    educational_qualification: PropTypes.string,
    designation: PropTypes.string,
    facebook_link: PropTypes.string,
    instagram_link: PropTypes.string,
    linkedin_link: PropTypes.string,
    bio: PropTypes.string,
    profile_image_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profile_image_url: PropTypes.string,
  }).isRequired,
};

export default LeadsManagementPage;