import React, { useState } from 'react';
import { Button, Modal, message, Progress } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteLead } from '../apiService';

const BulkDeleteLeads = ({ selectedLeads, onDeleteComplete, disabled }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    if (!isDeleting) {
      setIsModalVisible(false);
    }
  };

  const handleBulkDelete = async () => {
    setIsDeleting(true);
    let successCount = 0;
    let failCount = 0;

    for (let i = 0; i < selectedLeads.length; i++) {
      try {
        await deleteLead(selectedLeads[i]);
        successCount++;
      } catch (error) {
        console.error(`Error deleting lead ${selectedLeads[i]}:`, error);
        failCount++;
      }
      setDeleteProgress(((i + 1) / selectedLeads.length) * 100);
    }

    setIsDeleting(false);
    setIsModalVisible(false);
    setDeleteProgress(0);

    if (successCount > 0) {
      message.success(`Successfully deleted ${successCount} lead(s)`);
    }
    if (failCount > 0) {
      message.error(`Failed to delete ${failCount} lead(s)`);
    }

    onDeleteComplete();
  };

  return (
    <>
      <Button
        type="primary"
        danger
        icon={<DeleteOutlined />}
        onClick={showModal}
        disabled={disabled || selectedLeads.length === 0}
        style={{ marginLeft: '10px' }}
      >
        Bulk Delete ({selectedLeads.length})
      </Button>
      <Modal
        title="Bulk Delete Leads"
        visible={isModalVisible}
        onOk={handleBulkDelete}
        onCancel={handleCancel}
        okText="Delete"
        okButtonProps={{ danger: true, disabled: isDeleting }}
        cancelButtonProps={{ disabled: isDeleting }}
      >
        <p>Are you sure you want to delete {selectedLeads.length} selected lead(s)?</p>
        <p>This action cannot be undone.</p>
        {isDeleting && (
          <Progress percent={Math.round(deleteProgress)} status="active" />
        )}
      </Modal>
    </>
  );
};

export default BulkDeleteLeads;